
// https://ionicframework.com/docs/theming/color-generator

/** Fjordkraft color variables **/
:root {
    --ion-color-primary: #FF5500;
    --ion-color-primary-rgb: 255,85,0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #e04b00;
    --ion-color-primary-tint: #ff661a;

    --ion-color-primary-dark: #FF5500;
    --ion-color-primary-dark-rgb: 255,85,0;
    --ion-color-primary-dark-contrast: #ffffff;
    --ion-color-primary-dark-contrast-rgb: 255,255,255;
    --ion-color-primary-dark-shade: #e04b00;
    --ion-color-primary-dark-tint: #ff661a;

    --ion-color-secondary: #8CC83C;
    --ion-color-secondary-rgb: 140,200,60;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #7bb035;
    --ion-color-secondary-tint: #98ce50;
  
    --ion-color-success: #8CC83C;
    --ion-color-success-rgb: 140,200,60;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #7bb035;
    --ion-color-success-tint: #98ce50;
  
    --ion-color-warning: #FFCC00;
    --ion-color-warning-rgb: 255,204,0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0b400;
    --ion-color-warning-tint: #ffd11a;
  
    --ion-color-danger: #f53d3d;
    --ion-color-danger-rgb: 245,61,61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #d83636;
    --ion-color-danger-tint: #f65050;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,36,40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-gray: #989aa2;
    --ion-color-gray-rgb: 152,154,162;
    --ion-color-gray-contrast: #000000;
    --ion-color-gray-contrast-rgb: 0,0,0;
    --ion-color-gray-shade: #86888f;
    --ion-color-gray-tint: #a2a4ab;
  
    --ion-color-light: #f8f8f8;
    --ion-color-light-rgb: 248,248,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #dadada;
    --ion-color-light-tint: #f9f9f9;

    --ion-color-toolbar: #FF5500;
    --ion-color-toolbar-rgb: 255,85,0;
    --ion-color-toolbar-contrast: #ffffff;
    --ion-color-toolbar-contrast-rgb: 255,255,255;
    --ion-color-toolbar-shade: #e04b00;
    --ion-color-toolbar-tint: #ff661a;

    --ion-color-toolbar-button: #222428;
    --ion-color-toolbar-button-rgb: 34,36,40;
    --ion-color-toolbar-button-contrast: #ffffff;
    --ion-color-toolbar-button-contrast-rgb: 255,255,255;
    --ion-color-toolbar-button-shade: #1e2023;
    --ion-color-toolbar-button-tint: #383a3e;

    --ion-color-black: #000000;
    --ion-color-black-rgb: 0,0,0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255,255,255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #dadada;
    --ion-color-white-tint: #f9f9f9;

    --ion-color-charging: #fda071;
    --ion-color-charging-rgb: 253,160,113;
    --ion-color-charging-contrast: #000000;
    --ion-color-charging-contrast-rgb: 0,0,0;
    --ion-color-charging-shade: #ec9c75;
    --ion-color-charging-tint: #fdaa7f;
}
.ion-color-primary{
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}
.ion-color-primary-dark{
    --ion-color-base: var(--ion-color-primary-dark);
    --ion-color-base-rgb: var(--ion-color-primary-dark-rgb);
    --ion-color-contrast: var(--ion-color-primary-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-dark-shade);
    --ion-color-tint: var(--ion-color-primary-dark-tint);
}
.ion-color-gray{
    --ion-color-base: var(--ion-color-gray);
    --ion-color-base-rgb: var(--ion-color-gray-rgb);
    --ion-color-contrast: var(--ion-color-gray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-shade);
    --ion-color-tint: var(--ion-color-gray-tint);
}
.ion-color-toolbar{
    --ion-color-base: var(--ion-color-toolbar);
    --ion-color-base-rgb: var(--ion-color-toolbar-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-shade);
    --ion-color-tint: var(--ion-color-toolbar-tint);
}
.ion-color-toolbar-button{
    --ion-color-base: var(--ion-color-toolbar-button);
    --ion-color-base-rgb: var(--ion-color-toolbar-button-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-button-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-button-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-button-shade);
    --ion-color-tint: var(--ion-color-toolbar-button-tint);
}
.ion-color-toolbar-contrast{
    --ion-color-base: var(--ion-color-toolbar-contrast);
}
.ion-color-black{
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-white{
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-success{
    --ion-color-base: var(--ion-color-success);
    --ion-color-base-rgb: var(--ion-color-success-rgb);
    --ion-color-contrast: var(--ion-color-success-contrast);
    --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
    --ion-color-shade: var(--ion-color-success-shade);
    --ion-color-tint: var(--ion-color-success-tint);
}


